import React from "react";
import Layout from "../components/layout";
import SaveYourSpeeches from "../components/SaveYourSpeeches";
import SEO from "../components/seo";

export default function IndexPage() {
  return (
    <Layout noContainer>
      <SEO title="Home" />
      <SaveYourSpeeches />
    </Layout>
  );
}
