import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import { BottomDownDivider, TopUpDivider } from "../components/dividers";
import AmazonSvg from "../images/amazon.svg";
import AppleMusicSvg from "../images/apple-music.svg";
import DeezerSvg from "../images/deezer.svg";
import GooglePlaySvg from "../images/google-play.svg";
import SpotifySvg from "../images/spotify.svg";
import TidalSvg from "../images/tidal.svg";
import YoutubeSvg from "../images/youtube.svg";

const MusicServiceIcon = styled.a`
  display: inline-block;
  margin-bottom: 0.5rem;
`;

export default function SaveYourSpeeches() {
  const { coverImage, backgroundImage } = useStaticQuery(graphql`
    query {
      coverImage: file(relativePath: { eq: "save-your-speeches/cover.jpg" }) {
        childImageSharp {
          fluid(quality: 88, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      backgroundImage: file(
        relativePath: { eq: "save-your-speeches/background.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 98, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <BackgroundImage
      fluid={backgroundImage.childImageSharp.fluid}
      Tag="section"
      style={{ backgroundPosition: "50% 65%", position: "relative" }}
      className="headings-night position-relative"
      backgroundColor="#000"
    >
      <BottomDownDivider />
      <TopUpDivider />
      <Container className="py-5">
        <Row>
          <Col lg={6} className="my-3">
            <Img fluid={coverImage.childImageSharp.fluid} />
          </Col>
          <Col lg={6} className="my-3 d-flex">
            <Row className="d-flex flex-column justify-content-between align-fill">
              <Col className="align-self-center align-self-lg-start" xs="auto">
                <h1 className="font-fancy">Save Your Speeches</h1>
                <p
                  style={{ fontWeight: 200, textTransform: "uppercase" }}
                  className="lead"
                >
                  Out Now
                </p>
                <p style={{ fontWeight: 200 }}>
                  Tokyo Green’s new eagerly awaited second album is finally
                  here! The album was officially released on 24th July 2020.
                  Listen to it now by clicking on your preferred music platform
                  below for a listening experience you won’t forget.
                </p>
              </Col>
              <Col className="" xs="auto">
                <Row className="justify-content-center justify-content-lg-start mt-3">
                  <Col xs="auto">
                    <MusicServiceIcon
                      href="https://open.spotify.com/album/6UnxgDfBU8P3SvTXyoQTNQ?si=2QLJph9bSHelYnINUase2A"
                      target="_blank"
                      rel="noreferrer"
                      className="image-link"
                      title="Save Your Speeches on Spotify"
                    >
                      <SpotifySvg
                        style={{
                          marginBottom: "0.5rem",
                          width: "10rem",
                          height: "3rem",
                        }}
                      />
                    </MusicServiceIcon>
                  </Col>
                  <Col xs="auto">
                    <MusicServiceIcon
                      href="https://music.apple.com/gb/album/save-your-speeches/1523622365"
                      target="_blank"
                      rel="noreferrer"
                      className="image-link"
                      title="Save Your Speeches on Apple Music"
                    >
                      <AppleMusicSvg
                        style={{
                          marginBottom: "0.5rem",
                          width: "10rem",
                          height: "3rem",
                        }}
                      />
                    </MusicServiceIcon>
                  </Col>
                  <Col xs="auto">
                    <MusicServiceIcon
                      href="https://www.youtube.com/playlist?list=OLAK5uy_ngLN4gB89DM4xy6wejeVQXYjRVmN9sJJU"
                      target="_blank"
                      rel="noreferrer"
                      className="image-link"
                      title="Save Your Speeches on YouTube"
                    >
                      <YoutubeSvg
                        style={{
                          marginBottom: "0.5rem",
                          width: "10rem",
                          height: "3rem",
                        }}
                      />
                    </MusicServiceIcon>
                  </Col>
                  <Col xs="auto">
                    <MusicServiceIcon
                      href="https://music.amazon.co.uk/albums/B08D32MM1G?ref=dm_sh_c5fb-6e5e-de55-2b25-f314e"
                      target="_blank"
                      rel="noreferrer"
                      className="image-link"
                      title="Save Your Speeches on Amazon Music"
                    >
                      <AmazonSvg
                        style={{
                          marginBottom: "0.5rem",
                          width: "10rem",
                          height: "3rem",
                        }}
                      />
                    </MusicServiceIcon>
                  </Col>
                  <Col xs="auto">
                    <MusicServiceIcon
                      href="https://play.google.com/store/music/album/Tokyo_Green_Save_Your_Speeches?id=B5lhylz6egwtouc7t4fri55tbty"
                      target="_blank"
                      rel="noreferrer"
                      className="image-link"
                      title="Save Your Speeches on Google Play"
                    >
                      <GooglePlaySvg
                        style={{
                          marginBottom: "0.5rem",
                          width: "10rem",
                          height: "3rem",
                        }}
                      />
                    </MusicServiceIcon>
                  </Col>
                  <Col xs="auto">
                    <MusicServiceIcon
                      href="https://tidal.com/browse/album/148670348"
                      target="_blank"
                      rel="noreferrer"
                      className="image-link"
                      title="Save Your Speeches on Tidal"
                    >
                      <TidalSvg
                        style={{
                          marginBottom: "0.5rem",
                          width: "10rem",
                          height: "3rem",
                        }}
                      />
                    </MusicServiceIcon>
                  </Col>
                  <Col xs="auto">
                    <MusicServiceIcon
                      href="https://www.deezer.com/en/album/161117852"
                      target="_blank"
                      rel="noreferrer"
                      className="image-link"
                      title="Save Your Speeches on Deezer"
                    >
                      <DeezerSvg
                        style={{
                          marginBottom: "0.5rem",
                          width: "10rem",
                          height: "3rem",
                        }}
                      />
                    </MusicServiceIcon>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
}
